
.body-bg {
  background-color: #9921e8;
  background-image: linear-gradient(315deg, #aa82ba 0%, #67cec0 74%);
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

body {
  font-family: 'Roboto', sans-serif;
}
